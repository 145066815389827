import React, { useEffect, useState, useLayoutEffect } from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { loadTokenPrices } from "../helpers";
import Loading from "../components/Loader";
import ReactPlayer from "react-player";

function Root() {
    const [loading, setLoading] = useState(true);
    const [videoName, setVideoName] = useState("/marcus_WEB_07.mp4");

    useEffect(() => {
        loadTokenPrices().then(() => setLoading(false));
    }, []);

    useLayoutEffect(() => {
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    const updateSize: any = () => {
        console.log("updating screen size");
        setVideoName(
            window.innerWidth / window.innerHeight < 2.4 ? (window.innerWidth / window.innerHeight < 1.33 ? "/marcus_mobile.mp4" : "/marcus_square.mp4") : "/marcus_WEB_07.mp4",
        );
    };

    // if (loading) return <Loading />;

    return (
        <BrowserRouter>
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    overflow: "hidden",
                    minWidth: "100vw",
                    filter: "brightness(100%)",
                    backgroundColor: "black",
                }}
            >
                {/* <ReactPlayer url={videoName} loop muted playsinline playing width={"100%"} height={"100%"} /> */}
            </div>
            <div
                style={{
                    overflow: "hidden",
                }}
            >
                <App />
            </div>
        </BrowserRouter>
    );
}

export default Root;
