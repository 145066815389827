import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { indigo } from "@mui/material/colors";
import MuiBox from "@mui/material/Box";
import MuiTab, { TabProps as MuiTabProps } from "@mui/material/Tab";
import MuiTabs from "@mui/material/Tabs";
import Button from "../../components/Button";
import Typography from "../../components/Typography";
import NFTItemTable from "./NFTItemTable";
import NFTTable from "./NFTTable";
import { BigNumber } from "ethers";
import { Link } from "react-router-dom";

const DT_FORMAT = "MMM DD, YYYY";

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

interface NFTItem {
    tokenId: string;
    node: string; // name of node uri of the asset's preview
    imageURI: string; // uri of the original asset
    createdAt: string; // string formatted date
    reward: number; // reward token
}

interface TabProps extends MuiTabProps {
    index: number;
    label: string;
}

interface TabPanelProps {
    children: React.ReactNode;
    value: number;
    index: number;
}

const Tab: React.FC<TabProps> = props => {
    const { children, index, label, ...otherProps } = props;
    return (
        <MuiTab
            label={<Typography sx={{ fontWeight: "bold", textTransform: "initial" }}>{label}</Typography>}
            {...a11yProps(index)}
            {...otherProps}
            sx={{ backgroundColor: "#5a5a5a" }}
        />
    );
};

const TabPanel: React.FC<TabPanelProps> = props => {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
            {value === index && (
                <MuiBox sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </MuiBox>
            )}
        </div>
    );
};

interface NftData {
    id: BigNumber;
    image: string;
    name: string;
    fee: BigNumber;
    monthlyCost: BigNumber;
}

interface TabContainerProps {
    openModal: (data: NftData) => void;
    reloadBalance: () => void;
    owned: Array<BigNumber>;
    staked: Array<BigNumber>;
    select: (id: BigNumber) => void;
    unselect: (id: BigNumber) => void;
    selected: Array<BigNumber>;
    setSelectedTab: (tab: number) => void;
}

const TabContainer: React.FC<TabContainerProps> = ({ openModal, owned, staked, reloadBalance, select, unselect, selected, setSelectedTab }) => {
    const [tabIndex, setTabIndex] = useState<number>(0);

    useEffect(() => {
        setSelectedTab(tabIndex);
    }, [tabIndex]);

    const onTabChange = (event: any, value: number) => {
        setTabIndex(value);
    };

    return (
        <MuiBox>
            <MuiTabs value={tabIndex} onChange={onTabChange} variant="fullWidth" textColor="inherit" TabIndicatorProps={{ style: { display: "none" } }} sx={{ borderRadius: 2.5 }}>
                <Tab label="NFTs" index={0} />
                <Tab label="Staked NFTs" index={1} />
            </MuiTabs>
            <MuiBox>
                <div style={{ display: tabIndex == 0 ? undefined : "none", paddingTop: "15px" }}>
                    <MuiBox sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}>
                        <Typography variant="subtitle2">Stake to earn rewards.</Typography>
                    </MuiBox>
                    {owned.filter(el => !staked.includes(el)).length == 0 && (
                        <MuiBox sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", flexWrap: "wrap", marginTop: "20px" }}>
                            <Typography variant="subtitle2">
                                You have no NFTs to stake.{" "}
                                <a
                                    style={{
                                        cursor: "pointer",
                                        color: "#c999ff",
                                        textDecoration: "underline",
                                    }}
                                    onClick={() => {
                                        window.open("https://www.stickmansaga.xyz/", "_blank");
                                    }}
                                >
                                    Buy them at www.stickmansaga.xyz.
                                </a>
                            </Typography>
                        </MuiBox>
                    )}
                    <MuiBox style={{ width: "100%" }}>
                        <NFTTable openModal={openModal} owned={owned} reloadBalance={reloadBalance} selected={selected} unselect={unselect} select={select} />
                    </MuiBox>
                </div>
                <div style={{ display: tabIndex == 1 ? undefined : "none", paddingTop: "15px" }}>
                    {staked.length == 0 && (
                        <MuiBox sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}>
                            <Typography variant="subtitle2">You have no NFTs Staked.</Typography>
                        </MuiBox>
                    )}
                    <MuiBox style={{ width: "100%" }}>
                        <NFTTable selected={selected} unselect={unselect} select={select} enrolled={true} owned={staked} reloadBalance={reloadBalance} />
                    </MuiBox>
                </div>
            </MuiBox>
        </MuiBox>
    );
};

export default TabContainer;
