import axios from "axios";

const cache: { [key: string]: number } = {};

export const loadTokenPrices = async () => {
    //const url = "https://api.coingecko.com/api/v3/simple/price?ids=avalanche-2,dominium,magic-internet-money,snowdog&vs_currencies=usd";
    //const { data } = await axios.get(url);

    cache["AVAX"] = 0; // data["avalanche-2"].usd;
    cache["MIM"] = 0; // data["magic-internet-money"].usd;
    cache["OHM"] = 0; // data["dominium"].usd;
    cache["SDOG"] = 0; // data["snowdog"].usd;
};

export const getTokenPrice = (symbol: string): number => {
    return Number(cache[symbol]);
};
