import { Networks, DEFAULD_NETWORK } from "../constants/blockchain";

export const networkMap = {
    [Networks.POLYGON]: {
        chainId: "0x1", // 137
        chainName: "Ethereum Mainnet",
        nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
        rpcUrls: ["https://mainnet.infura.io/v3/def02d27706042989d1feb553d8ee23f", "https://mainnet.infura.io/v3/4342fb7a8a4a4641b1a9c586c8102049"],
        blockExplorerUrls: ["https://etherscan.io/"],
    },
    [Networks.TEST]: {
        chainId: "0x13881", // 80001
        chainName: "Matic(Polygon) Mumbai Testnet",
        nativeCurrency: { name: "tMATIC", symbol: "tMATIC", decimals: 18 },
        rpcUrls: ["https://rpc-mumbai.matic.today"],
        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
    },
};

const switchRequest = () => {
    return window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: networkMap[DEFAULD_NETWORK].chainId }],
    });
};

const addChainRequest = () => {
    return window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [networkMap[DEFAULD_NETWORK]],
    });
};

export const swithNetwork = async () => {
    if (window.ethereum) {
        try {
            await switchRequest();
        } catch (error: any) {
            if (error.code === 4902) {
                try {
                    await addChainRequest();
                    await switchRequest();
                } catch (addError) {
                    console.log(error);
                }
            }
            console.log(error);
        }
    }
};
