import { messages } from "../../constants/messages";

export default function formatErrorMessage(error: any) {
    console.log("___formatErrorMessage");
    console.log(error);
    if (error.data && error.data.message) {
        console.log("returning error.data.message");
        return error.data.message;
    } else if (error.message) {
        console.log("returning error.message");
        return error.message;
    } else {
        console.log("returning something_wrong");
        return messages.something_wrong;
    }
}
