import React from "react";
import { createTheme, Theme as MuiTheme, ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { blue, purple } from "@mui/material/colors";

const overrides = {
    MuiTab: {
        // general overrides for your material tab component here
        root: {
            backgroundColor: "red",
            "&$selected": {
                backgroundColor: "blue",
            },
        },
    },
};

const theme: MuiTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#fff",
        },
        secondary: {
            main: purple["A700"],
        },
        background: {
            default: "#0a0a0a",
        },
        // error: {},
        // info: {},
        // warning: {},
        // success: {},
        // text: {
        //     primary: "#fff",
        // },
    },
    typography: {
        fontFamily: "'Be Vietnam Pro', sans-serif",
        h1: {
            color: "#fff",
        },
        h2: {
            color: "#fff",
        },
        h3: {
            color: "#fff",
        },
        h4: {
            color: "#fff",
        },
        h5: {
            color: "#fff",
        },
        h6: {
            color: "#fff",
        },
        subtitle1: {
            color: "#fff",
        },
        subtitle2: {
            color: "#fff",
        },
        body1: {
            color: "#fff",
        },
        body2: {
            color: "#fff",
        },
        button: {
            fontWeight: "bold",
        },
    },
});

interface ThemeProviderProps {
    children?: React.ReactChild;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
