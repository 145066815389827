import { BigNumber, ethers } from "ethers";
import { getAddresses } from "../../constants";
import { PresaleContract, Token, StakingContract, MemoTokenContract, TimeTokenContract } from "../../abi";
import { setAll, getMarketPrice, getTokenPrice } from "../../helpers";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { JsonRpcProvider } from "@ethersproject/providers";
import { RootState } from "../store";
import allBonds from "../../helpers/bond";

interface ILoadAppDetails {
    networkID: number;
    provider: JsonRpcProvider;
}

export const loadAppDetails = createAsyncThunk(
    "app/loadAppDetails",
    //@ts-ignore
    async ({ networkID, provider }: ILoadAppDetails) => {
        console.log("app/loadAppDetails");
        try {
            const mimPrice = getTokenPrice("MIM");
            const addresses = getAddresses(networkID);
            const ohmPrice = getTokenPrice("OHM");
            const ohmAmount = 1512.12854088 * ohmPrice;

            const stakingContract = new ethers.Contract(addresses.STAKING_ADDRESS, StakingContract, provider);
            const currentBlock = 0; /*await provider.getBlockNumber().catch((e: any) => {
                console.log(e);
                return 0;
            });*/
            // const currentBolckObj = await provider.getBlock(currentBlock).catch((e: any) => {
            //     console.log(e);
            //     return { timestamp: 0 };
            // });
            const currentBlockTime = 0; //currentBolckObj.timestamp;
            const ssbContract = new ethers.Contract(addresses.SSB_ADDRESS, MemoTokenContract, provider);
            const sbContract = new ethers.Contract(addresses.SB_ADDRESS, TimeTokenContract, provider);
            const mimContract = new ethers.Contract(addresses.MIM_ADDRESS, TimeTokenContract, provider);
            const token = new ethers.Contract(addresses.TOKEN_ADDRESS, Token, provider);
            const marketPrice = 4; //((await getMarketPrice(networkID, provider)) / Math.pow(10, 9)) * mimPrice;
            // const totalSupplyRaw = await sbContract.totalSupply().catch((e: any) => {
            //     console.log(e);
            //     return 0;
            // });
            const totalSupply = 0; //totalSupplyRaw / Math.pow(10, 9);
            const circSupplyRaw = 0; /*await ssbContract.circulatingSupply().catch((e: any) => {
                console.log(e);
                return 0;
            });*/
            const circSupply = circSupplyRaw / Math.pow(10, 9);

            const stakingTVL = circSupply * marketPrice;
            const marketCap = totalSupply * marketPrice;
            // Going to custom code in each RFV asset
            const redeemRfvRaw = 0; /* await mimContract.balanceOf(addresses.TREASURY_ADDRESS).catch((e: any) => {
                console.log(e);
                return 0;
            });*/
            const redeemRfv = redeemRfvRaw / Math.pow(10, 18); // (await redeemContract.RFV()) / Math.pow(10, 18)
            const redeemSbSentRaw = 0; /*await sbContract.balanceOf(addresses.REDEEM_ADDRESS).catch((e: any) => {
                console.log(e);
                return 0;
            });*/
            const redeemSbSent = redeemSbSentRaw / Math.pow(10, 9);

            const redeemMimAvailableRaw = 0; //await mimContract.balanceOf(addresses.REDEEM_ADDRESS);
            const redeemMimAvailable = redeemMimAvailableRaw / Math.pow(10, 18);

            // const tokenBalPromises = allBonds.map(bond => bond.getTreasuryBalance(networkID, provider));

            // var tokenBalances: number[] = [0, 0, 0, 0];
            // try {
            //     tokenBalances = await Promise.all(tokenBalPromises);
            // } catch (error) {
            //     console.log(error);
            // }

            // const treasuryBalance = tokenBalances.reduce((tokenBalance0, tokenBalance1) => tokenBalance0 + tokenBalance1) + redeemMimAvailable;
            // const tokenAmountsPromises = allBonds.map(bond => bond.getTokenAmount(networkID, provider));
            // var tokenAmounts: number[] = [0, 0, 0, 0];
            // try {
            //     tokenAmounts = await Promise.all(tokenAmountsPromises);
            // } catch (error) {
            //     console.log(error);
            // }
            // console.log("tokenBalances: ", tokenAmounts);
            // const rfvTreasury = treasuryBalance; //tokenBalances[0] + tokenBalances[1] + redeemMimAvailable + tokenBalances[2] / 2 + tokenBalances[3] / 2;
            // const daoSb = await sbContract.balanceOf(addresses.DAO_ADDRESS).catch((e: any) => {
            //     console.log(e);
            //     return 0;
            // });
            // const daoSbAmount = Number(ethers.utils.formatUnits(daoSb, "gwei"));

            // const sbBondsAmountsPromises = allBonds.map(bond => bond.getSbAmount(networkID, provider));
            // var sbBondsAmounts: number[] = [0];
            // try {
            //     sbBondsAmounts = await Promise.all(sbBondsAmountsPromises);
            // } catch (error) {
            //     console.log(error);
            // }

            // const LpSbAmount = sbBondsAmounts.reduce((sbAmount0, sbAmount1) => sbAmount0 + sbAmount1, 0);
            // const sbSupply = totalSupply - LpSbAmount - daoSbAmount;

            // const rfv = rfvTreasury / (sbSupply - redeemSbSent);
            // const deltaMarketPriceRfv = ((rfv - marketPrice) / rfv) * 100;
            // const epoch = { distribute: 0, endTime: 0 }; /* await stakingContract.epoch().catch((e: any) => {
            //     console.log(e);
            //     return 0;
            // });*/
            // const stakingReward = epoch.distribute;
            // const circ = 0; /* await ssbContract.circulatingSupply().catch((e: any) => {
            //     console.log(e);
            //     return 0;
            // });*/
            // const stakingRebase = stakingReward / circ;
            // const fiveDayRate = Math.pow(1 + stakingRebase, 5 * 3) - 1;
            // const stakingAPY = Math.pow(1 + stakingRebase, 365 * 3) - 1;

            // const currentIndex = 0;
            // // const currentIndex = await stakingContract.index().catch((e: any) => {
            // //     console.log(e);
            // //     return 0;
            // // });
            // const nextRebase = epoch.endTime;
            // console.log("Runaway: ", rfvTreasury, circSupply);
            // const treasuryRunway = rfvTreasury / circSupply;
            // const runway = Math.log(treasuryRunway) / Math.log(1 + stakingRebase) / 3;

            return {
                currentIndex: 0, //Number(ethers.utils.formatUnits(currentIndex, "gwei")),
                totalSupply: 0,
                marketCap,
                currentBlock,
                circSupply,
                fiveDayRate: 0,
                treasuryBalance: 0,
                stakingAPY: 0,
                stakingTVL,
                stakingRebase: 0,
                marketPrice,
                deltaMarketPriceRfv: 0,
                currentBlockTime,
                nextRebase: 0,
                rfv: 0,
                runway: 0,
                redeemRfv,
                redeemSbSent,
                redeemMimAvailable,
                token,
            };
        } catch (error) {
            console.log("ERROR: ", error);
            return {
                currentIndex: 1,
                totalSupply: 1,
                marketCap: 1,
                currentBlock: 1,
                circSupply: 1,
                fiveDayRate: 1,
                treasuryBalance: 1,
                stakingAPY: 1,
                stakingTVL: 1,
                stakingRebase: 1,
                marketPrice: 1,
                deltaMarketPriceRfv: 1,
                currentBlockTime: 1,
                nextRebase: 1,
                rfv: 1,
                runway: 1,
                redeemRfv: 1,
                redeemSbSent: 1,
                redeemMimAvailable: 1,
                totalRaise: 1,
                token: 1,
            };
        }
    },
);

const initialState = {
    loading: true,
};

export interface IAppSlice {
    loading: boolean;
    stakingTVL: number;
    marketPrice: number;
    deltaMarketPriceRfv: number;
    marketCap: number;
    circSupply: number;
    currentIndex: string;
    currentBlock: number;
    currentBlockTime: number;
    fiveDayRate: number;
    treasuryBalance: number;
    stakingAPY: number;
    stakingRebase: number;
    networkID: number;
    nextRebase: number;
    totalSupply: number;
    rfv: number;
    runway: number;
    redeemRfv: number;
    redeemSbSent: number;
    redeemMimAvailable: number;
    totalRaise: number;
    hiker: number;
    climber: number;
    caveman: number;
    pubsale: number;
}

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        fetchAppSuccess(state, action) {
            setAll(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadAppDetails.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(loadAppDetails.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadAppDetails.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            });
    },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
