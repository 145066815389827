import { useState, useCallback, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { trim } from "../../helpers";
import { changeStake, changeApproval, changeRedeemApproval, changeRedeem } from "../../store/slices/stake-thunk";
import "../Stake/stake.scss";
import { useWeb3Context } from "../../hooks";
import { IPendingTxn, isPendingTxn, txnButtonText } from "../../store/slices/pending-txns-slice";
import { Skeleton } from "@material-ui/lab";
import { IReduxState } from "../../store/slices/state.interface";
import { messages } from "../../constants/messages";
import classnames from "classnames";
import { warning } from "../../store/slices/messages-slice";
import { IAppSlice } from "../../store/slices/app-slice";
import { ethers, Signer, BigNumber } from "ethers";
import { useTranslation } from "react-i18next";
import RedeemTimer from "../../components/RedeemTimer";
import { MimTokenContract, Token, StakingContract, MemoTokenContract, TimeTokenContract, RedeemContract, NftStaking, NftERC721 } from "../../abi";
import { getAddresses } from "../../constants";
import { JsonRpcProvider } from "@ethersproject/providers";

import { indigo } from "@mui/material/colors";
import MuiBox from "@mui/material/Box";
import MuiCard from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "../../components/Typography";
import TabContainer from "./TabContainer";
import circle from "../../assets/icons/circle-hero.png";
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import formatError from "./ErrorFormat";
import ClaimingDialog from "./ClaimDialog";

interface PresaleInfo {
    tier: number;
    deposited: BigNumber;
    claimable: BigNumber;
    total: BigNumber;
    domTotal: BigNumber;
}

interface NftData {
    id: BigNumber;
    image: string;
    name: string;
    fee: BigNumber;
    monthlyCost: BigNumber;
}

function StakeNFT() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { provider, signer, address, connect, chainID, checkWrongNetwork } = useWeb3Context();
    // const address = "0xd176f3389063181A490A601F7dfE0e58488dc171";
    // checkWrongNetwork();
    // connect();

    const app = useSelector<IReduxState, IAppSlice>(state => state.app);
    const [claimingAll, setClaimingAll] = useState<boolean>(false);
    const [payingAll, setPayingAll] = useState<boolean>(false);
    const [approving, setApproving] = useState<boolean>(false);
    const [nftBalance, setNftBalance] = useState<Array<BigNumber>>([]);
    const [nftBalanceStaked, setNftBalanceStaked] = useState<Array<BigNumber>>([]);

    const [open, setOpen] = useState<boolean>(false);
    const [modalOpenedFor, setModalOpenedFor] = useState<BigNumber | null>(null);
    const [depositSelectedFor, setDepositSelectedFor] = useState<Array<BigNumber>>([]);
    const [checked, setChecked] = useState<boolean>(false);
    const [enrol, setEnrol] = useState<boolean>(false);
    const [enrolling, setEnrolling] = useState<boolean>(false);
    const [DOMapproved, setDOMapproved] = useState<boolean>(false);
    const [NFTapproved, setNFTapproved] = useState<boolean>(false);
    const [claimAllDialog, setClaimAllDialog] = useState<boolean>(false);
    const [claimable, setClaimable] = useState<BigNumber>(BigNumber.from(0));
    const [totalDOMgenerated, setTotalDOMgenerated] = useState<BigNumber>(BigNumber.from(0));
    const [tabSelected, setTabSelected] = useState<number>(0);
    const [withdrawFee, setWithdrawFee] = useState<BigNumber>(BigNumber.from(0));

    const pendingTransactions = useSelector<IReduxState, IPendingTxn[]>(state => {
        return state.pendingTransactions;
    });

    useEffect(() => {
        setEnrol(tabSelected == 1);
        setDepositSelectedFor([]);
    }, [tabSelected]);

    const addresses = getAddresses(chainID);

    useEffect(() => {
        if (depositSelectedFor.length == 0) {
            setWithdrawFee(BigNumber.from(0));
            return;
        }
        new ethers.Contract(addresses.NFT_STAKING, NftStaking, provider)
            .getWithdrawlFeesForTokens(depositSelectedFor)
            .then(setWithdrawFee)
            .catch((e: any) => {
                console.log("EROOR AT FEESa");
                console.error(e);
            });
    }, [depositSelectedFor]);

    useEffect(() => {
        if (nftBalanceStaked.length == 0) {
            return;
        }

        new ethers.Contract(addresses.NFT_STAKING, NftStaking, provider)
            .getClaimableAmount(address)
            .then((cl: BigNumber) => {
                setClaimable(cl);
            })
            .catch((e: any) => {
                console.log("EROOR AT claimable");
                console.error(e);
            });
    }, [claimingAll, nftBalanceStaked]);

    useEffect(() => {
        if (nftBalanceStaked.length == 0) {
            return;
        }
        new ethers.Contract(addresses.NFT_STAKING, NftStaking, provider)
            .claimReward()
            .then((rw: BigNumber) => {
                new ethers.Contract(addresses.NFT_STAKING, NftStaking, provider)
                    .claimLength()
                    .then((ln: BigNumber) => {
                        setTotalDOMgenerated(rw.div(ln).mul(BigNumber.from(86400)));
                    })
                    .catch((e: any) => {
                        console.log("EROOR AT stix a day");
                        console.error(e);
                    });
            })
            .catch((e: any) => {
                console.log("EROOR AT stix a day");
                console.error(e);
            });
    }, [claimingAll, nftBalanceStaked]);

    useEffect(() => {
        const nftStakingContract = new ethers.Contract(addresses.NFT_STAKING, NftStaking, provider);
        nftStakingContract
            .getTokenIdsForAddressExternal(address)
            .then((bal: any) => {
                console.log("NFT balance: ", bal);
                //setNftBalance([1, 2, 234, 543, 321, 961, 357, 125, 45, 292, 450, 11, 5, 728].map(n => BigNumber.from(n)));
                //setNftBalance([...bal, ethers.BigNumber.from(1), ethers.BigNumber.from(232)]);
                setNftBalance(bal);
            })

            .catch((e: any) => {
                console.log(e);
                dispatch(warning({ text: messages.something_wrong, error: e }));
            });
    }, []);

    useEffect(() => {
        const nftStakingContract = new ethers.Contract(addresses.NFT_STAKING, NftStaking, provider);

        nftStakingContract
            .getTokenIdsForAddress(address)
            .then((bal: any) => {
                console.log("NFT balance Staking: ", bal);
                setNftBalanceStaked(bal);
                //setNftBalanceStaked([...bal, ethers.BigNumber.from(1), ethers.BigNumber.from(396)]);

                //return refreshClaimable();
            })
            .catch((e: any) => {
                console.log("!2");
                console.log(e);
                //dispatch(warning({ text: messages.something_wrong, error: e }));
            });
    }, []);

    useEffect(() => {
        try {
            connect().then((prov: any) => {
                new ethers.Contract(addresses.NFT_ERC721, NftERC721, prov)
                    .isApprovedForAll(address, addresses.NFT_STAKING)
                    .then((ok: boolean) => {
                        setNFTapproved(ok);
                    })
                    .catch((e: any) => {
                        console.log(address, addresses.NFT_STAKING);
                        console.log(e);
                    });
            });
        } catch (error) {
            console.log(error);
            //dispatch(warning({ text: formatError(error), error: error }));
        }
    }, [modalOpenedFor]);

    const reloadBalance = () => {
        const nftStakingContract = new ethers.Contract(addresses.NFT_STAKING, NftStaking, provider);
        connect()
            .then(() => {
                nftStakingContract
                    .getTokenIdsForAddress(address)
                    .then((bal: any) => {
                        console.log("NFT balance Staking: ", bal);
                        setNftBalanceStaked(bal);
                    })
                    .catch((e: any) => {
                        console.log("14331");
                        console.log(e);
                        dispatch(warning({ text: formatError(e), error: e }));
                    });
            })
            .catch((e: any) => {
                console.log("!234");
                console.log(e);
                //dispatch(warning({ text: messages.something_wrong, error: e }));
            });
        connect()
            .then(() => {
                nftStakingContract
                    .getTokenIdsForAddressExternal(address)
                    .then((bal: any) => {
                        console.log("NFT balance: ", bal);
                        setNftBalance(bal);
                    })
                    .catch((e: any) => {
                        console.log(e);
                        dispatch(warning({ text: formatError(e), error: e }));
                    });
            })
            .catch((e: any) => {
                console.log("!4");
                console.log(e);
                //dispatch(warning({ text: messages.something_wrong, error: e }));
            });
    };

    const openModal = () => {
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
        setModalOpenedFor(null);
        setChecked(false);
        setDepositSelectedFor([]);
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const claimAll = async () => {
        try {
            setClaimingAll(true);
            const w3Prov = await connect();
            const tx = await new ethers.Contract(addresses.NFT_STAKING, NftStaking, signer).claim();
            await tx.wait();
            setClaimingAll(false);
        } catch (error) {
            setClaimingAll(false);
            console.log(error);
            dispatch(warning({ text: formatError(error), error: error }));
        }
    };

    const setDialogContent = () => {
        const onApprove = async () => {
            console.log("Approve for ", signer);
            try {
                setApproving(true);
                console.log(signer);
                const tx1 = await new ethers.Contract(addresses.NFT_ERC721, NftERC721, signer).setApprovalForAll(addresses.NFT_STAKING, true, { from: address });
                await tx1.wait();
                setNFTapproved(true);
                setApproving(false);
            } catch (error) {
                console.log(error);
                setApproving(false);
                dispatch(warning({ text: formatError(error), error: error }));
            }
        };
        const onEnrollToS = async () => {
            console.log("Enrolle for ", modalOpenedFor?.toString());
            try {
                setEnrolling(true);
                console.log("addresses.NFT_STAKING", addresses.NFT_STAKING);
                const tx = await new ethers.Contract(addresses.NFT_STAKING, NftStaking, signer).depositNFTs(depositSelectedFor);
                await tx.wait();
                closeModal();
                reloadBalance();
                setTimeout(() => {
                    setEnrolling(false);
                }, 2000);
            } catch (error: any) {
                console.log(error);
                setEnrolling(false);
                dispatch(warning({ text: formatError(error), error }));
            }
        };

        const onDropOut = async () => {
            console.log("Enrolle for ", depositSelectedFor);
            try {
                setEnrolling(true);
                console.log("addresses.NFT_STAKING", addresses.NFT_STAKING);
                const tx = await new ethers.Contract(addresses.NFT_STAKING, NftStaking, signer).withdraw(depositSelectedFor, { value: withdrawFee });
                await tx.wait();
                closeModal();
                reloadBalance();
                setTimeout(() => {
                    setEnrolling(false);
                }, 2000);
            } catch (error: any) {
                console.log(error);
                setEnrolling(false);
                dispatch(warning({ text: formatError(error), error }));
            }
        };

        let content = <></>;
        if (!open) content = <></>;
        if (!enrol) {
            content = (
                <MuiBox
                    sx={{
                        backgroundColor: "#000",
                        border: "2px solid rgba(255,255,255,0.3)",
                        backdropFilter: "blur(30px)",
                    }}
                >
                    <DialogTitle>
                        <Typography textAlign="center">Before you stake</Typography>
                        <IconButton
                            aria-label="close"
                            onClick={closeModal}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: theme => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{ color: "#bebebe" }}>
                        <Divider sx={{ margin: "20px 0", borderColor: "#bebebe" }} />
                        <Typography mb={2}>Each Stickman NFT staked on www.stickmansaga.wtf will receive daily $STIX rewards.</Typography>
                        <Typography mb={2}>A minimum of 2 Stickman NFTs are required in order to earn staking rewards.</Typography>

                        <Typography mb={2}>NFTs can be unstaked at anytime, if an NFT is unstaked before the 30 day lock up period will incur a .01 Eth fee.</Typography>
                        <Typography mb={2}>These costs are necessary to create sustainable ecosystem. 100% of funds go back into the community treasury.</Typography>
                        <Divider sx={{ margin: "20px 0", borderColor: "#bebebe" }} />
                        <FormControlLabel control={<Checkbox checked={checked} onChange={handleCheckboxChange} />} label="I understand." />
                        <Divider sx={{ margin: "20px 0", borderColor: "#bebebe" }} />
                        <MuiBox sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                            {!NFTapproved ? (
                                <Button
                                    disabled={!checked}
                                    onClick={onApprove}
                                    sx={{
                                        background: "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(36, 36, 36, 0.355) 100%)",
                                        borderRadius: "17px",
                                        padding: "5px 25px",
                                        border: "0.994648px solid rgba(255, 255, 255, 0.3)",
                                        margin: "10px",
                                    }}
                                >
                                    {approving ? "Approving contract...." : "Approve Contract"}
                                </Button>
                            ) : (
                                <Button
                                    disabled={!checked || enrolling || nftBalanceStaked.length + depositSelectedFor.length < 2}
                                    onClick={onEnrollToS}
                                    sx={{
                                        background: "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(36, 36, 36, 0.355) 100%)",
                                        borderRadius: "17px",
                                        padding: "5px 25px",
                                        border: "0.994648px solid rgba(255, 255, 255, 0.3)",
                                        margin: "10px",
                                    }}
                                >
                                    {enrolling ? "Staking..." : "Stake"}
                                </Button>
                            )}
                        </MuiBox>
                    </DialogContent>
                </MuiBox>
            );
        } else {
            content = (
                <MuiBox
                    sx={{
                        backgroundColor: "#000",
                        border: "2px solid rgba(255,255,255,0.3)",
                        backdropFilter: "blur(30px)",
                    }}
                >
                    <DialogTitle>
                        <Typography textAlign="center">Before you unstake:</Typography>

                        <IconButton
                            aria-label="close"
                            onClick={closeModal}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: theme => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{ color: "#bebebe" }}>
                        <Divider sx={{ margin: "20px 0", borderColor: "#bebebe" }} />
                        <Typography mb={2}>Unstaking early (before the first 30 days) will cause a fee of .01 ETH to occur.</Typography>
                        <Typography mb={2}>You will need at least 2 Stickman NFTs actively staked in order to receive $STIX, you cannot leave 1 Stickman NFT staked.</Typography>
                        {withdrawFee.gt(0) && <Typography mb={2}>You need to pay a fee of {ethers.utils.formatEther(withdrawFee)} ETH to unstake.</Typography>}

                        <Divider sx={{ margin: "20px 0", borderColor: "#bebebe" }} />
                        <FormControlLabel control={<Checkbox checked={checked} onChange={handleCheckboxChange} />} label="I understand." />
                        <Divider sx={{ margin: "20px 0", borderColor: "#bebebe" }} />
                        <MuiBox sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                            {!NFTapproved ? (
                                <Button
                                    disabled={!checked}
                                    onClick={onApprove}
                                    sx={{
                                        background: "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(36, 36, 36, 0.355) 100%)",
                                        borderRadius: "17px",
                                        padding: "5px 25px",
                                        border: "0.994648px solid rgba(255, 255, 255, 0.3)",
                                        margin: "10px",
                                    }}
                                >
                                    {approving ? "Approving contract...." : "Approve Contract"}
                                </Button>
                            ) : (
                                <Button
                                    disabled={!checked || enrolling || nftBalanceStaked.length - depositSelectedFor.length == 1}
                                    onClick={onDropOut}
                                    sx={{
                                        background: "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(36, 36, 36, 0.355) 100%)",
                                        borderRadius: "17px",
                                        padding: "5px 25px",
                                        border: "0.994648px solid rgba(255, 255, 255, 0.3)",
                                        margin: "10px",
                                    }}
                                >
                                    {enrolling ? "Unstaking..." : "Unstake"}
                                </Button>
                            )}
                        </MuiBox>
                    </DialogContent>
                </MuiBox>
            );
        }
        return content;
    };

    return (
        <div>
            <Dialog sx={{ backfaceVisibility: "hidden" }} open={open} onClose={closeModal}>
                {setDialogContent()}
            </Dialog>
            <Zoom in={true}>
                <MuiBox
                    borderRadius={"4px"}
                    padding={"30px"}
                    position={"relative"}
                    margin={"auto"}
                    sx={{
                        width: "89%",
                        maxWidth: "833px",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        border: "1px solid #5e5e5e",
                        borderRadius: "10px",
                        backdropFilter: "blur(30px)",
                    }}
                >
                    {/* <MuiBox component="img" src={circle} sx={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", zIndex: -1 }} /> */}
                    <MuiBox>
                        <MuiBox sx={{ width: "100%", display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                            <MuiBox sx={{ display: "flex", flexDirection: "column", gap: "6px" }}>
                                <Typography variant="h6" fontWeight="bold">
                                    My Stickmen
                                </Typography>
                                <Typography variant="body2" fontWeight="bold">
                                    {nftBalanceStaked.length} out of 20 Stickmen
                                </Typography>
                            </MuiBox>

                            {nftBalanceStaked.length > 0 ? (
                                <MuiBox sx={{ display: "flex", flexDirection: "column", gap: "6px" }}>
                                    <Typography variant="h6" fontWeight="bold" sx={{ textAlign: { xs: "left", md: "right" } }}>
                                        My Rewards
                                    </Typography>
                                    <Typography variant="body2" fontWeight="bold" sx={{ textAlign: { xs: "left", md: "right" } }}>
                                        Earned: {Number(ethers.utils.formatEther(claimable)).toFixed(2)} STIX
                                    </Typography>
                                    <Typography variant="body2" fontWeight="bold" sx={{ textAlign: { xs: "left", md: "right" } }}>
                                        Earning: {Number(ethers.utils.formatEther(totalDOMgenerated)).toFixed(2)} STIX/day
                                    </Typography>
                                    <MuiBox
                                        sx={{
                                            marginLeft: "auto",
                                            marginRight: "10px",
                                        }}
                                    >
                                        {depositSelectedFor.length > 0 && (
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                                                    backdropFilter: "blur(20px)",
                                                    padding: "3px 15px",
                                                    fontSize: "14px",
                                                    color: "white",
                                                    marginRight: "18px",
                                                    width: "9rem",
                                                }}
                                                onClick={() => {
                                                    setOpen(true);
                                                }}
                                            >
                                                {enrol ? "unstake" : "stake"}
                                            </Button>
                                        )}
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                // background: "linear-gradient(180deg, rgba(139, 68, 255, 0.5) -20%, rgba(70, 132, 255, 0.355) 100%)",
                                                backgroundColor: "rgba(255, 255, 255, 0.3)",
                                                backdropFilter: "blur(20px)",
                                                // border: "1px solid #5e5e5e",
                                                // borderRadius: "10px",
                                                padding: "3px 15px",
                                                fontSize: "14px",
                                                color: "white",
                                                marginRight: "-13px",
                                                width: "9rem",
                                            }}
                                            onClick={() => {
                                                setClaimAllDialog(true);
                                            }}
                                            disabled={claimingAll}
                                        >
                                            {"Claim"}
                                        </Button>
                                    </MuiBox>
                                </MuiBox>
                            ) : (
                                depositSelectedFor.length > 0 && (
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            backgroundColor: "rgba(255, 255, 255, 0.3)",
                                            backdropFilter: "blur(20px)",
                                            padding: "3px 15px",
                                            fontSize: "14px",
                                            color: "white",
                                            marginRight: "18px",
                                            width: "9rem",
                                        }}
                                        onClick={() => {
                                            setOpen(true);
                                        }}
                                    >
                                        {enrol ? "unstake" : "stake"}
                                    </Button>
                                )
                            )}
                        </MuiBox>

                        <MuiBox sx={{ width: "100%", marginTop: "30px" }}>
                            <TabContainer
                                openModal={openModal}
                                select={(id: BigNumber) => {
                                    setDepositSelectedFor(prev => [...prev, id]);
                                }}
                                unselect={(id: BigNumber) => {
                                    setDepositSelectedFor(prev => prev.filter(idp => idp != id));
                                }}
                                selected={depositSelectedFor}
                                owned={nftBalance}
                                staked={nftBalanceStaked}
                                reloadBalance={reloadBalance}
                                setSelectedTab={setTabSelected}
                            />
                        </MuiBox>
                    </MuiBox>
                </MuiBox>
            </Zoom>
            <ClaimingDialog
                all
                setClaimDialog={setClaimAllDialog}
                claiming={claimingAll}
                claimDialog={claimAllDialog}
                onClaim={claimAll}
                claimable={claimable}
                token={addresses.SB_ADDRESS}
            ></ClaimingDialog>
        </div>
    );
}
export default StakeNFT;
