import { Networks } from "./blockchain";

const POLYGON_TESTNET = {
    DAO_ADDRESS: "0x0000000000000000000000000000000000000000",
    SSB_ADDRESS: "0x0000000000000000000000000000000000000000",
    WSSB_ADDRESS: "0x0000000000000000000000000000000000000000",
    SB_ADDRESS: "0x1495f971931CAB5D9F24E077C4Bfc916a290729f",
    MIM_ADDRESS: "0x1495f971931CAB5D9F24E077C4Bfc916a290729f", // shold be usdc on poligon?
    STAKING_ADDRESS: "0x0000000000000000000000000000000000000000",
    STAKING_HELPER_ADDRESS: "0x0000000000000000000000000000000000000000",
    SB_BONDING_CALC_ADDRESS: "0x0000000000000000000000000000000000000000",
    TREASURY_ADDRESS: "0x0000000000000000000000000000000000000000",
    ZAPIN_ADDRESS: "0x0000000000000000000000000000000000000000",
    REDEEM_ADDRESS: "0x0000000000000000000000000000000000000000",
    TOKEN_ADDRESS: "0x1495f971931CAB5D9F24E077C4Bfc916a290729f",
    NFT_STAKING: "0x9c4CEa0189Bb2A425Ecd45E1a1164E2A8Ca8b501",
    NFT_ERC721: "0xd3Cd1287e6A7069a183a387C917ABDA4bbE3a361",
};

const POLYGON_MAINNET = {
    DAO_ADDRESS: "0x0000000000000000000000000000000000000000",
    SSB_ADDRESS: "0x0000000000000000000000000000000000000000",
    WSSB_ADDRESS: "0x0000000000000000000000000000000000000000",
    SB_ADDRESS: "0xE3B93acc2a8d64EE8f98514889CAa5778898a121",
    MIM_ADDRESS: "0x0000000000000000000000000000000000000000", // shold be usdc on poligon?
    STAKING_ADDRESS: "0x0000000000000000000000000000000000000000",
    STAKING_HELPER_ADDRESS: "0x0000000000000000000000000000000000000000",
    SB_BONDING_CALC_ADDRESS: "0x0000000000000000000000000000000000000000",
    TREASURY_ADDRESS: "0x0000000000000000000000000000000000000000",
    ZAPIN_ADDRESS: "0x0000000000000000000000000000000000000000",
    REDEEM_ADDRESS: "0x0000000000000000000000000000000000000000",
    TOKEN_ADDRESS: "0xE3B93acc2a8d64EE8f98514889CAa5778898a121",
    NFT_STAKING: "0xe811be4698a45b19e7F4Ab807E911AC9bb8d359F",
    NFT_ERC721: "0x0c052FDDebBE76697FaDF0A502db9eD54f210f87",
};

export const getAddresses = (networkID: number) => {
    if (networkID === Networks.POLYGON) return POLYGON_MAINNET;
    if (networkID === Networks.TEST) return POLYGON_TESTNET;

    throw Error("Network don't support");
};
