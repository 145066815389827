import React, { useEffect, useState } from "react";
import MuiDialog from "@mui/material/Dialog";
import MuiCardMedia from "@mui/material/CardMedia";
import { ethers, Signer, BigNumber } from "ethers";
import { MimTokenContract, Token, StakingContract, MemoTokenContract, TimeTokenContract, RedeemContract, NftStaking, NftERC721 } from "../../abi";
import {
    Box,
    Button,
    Chip,
    Typography,
    Grid,
    Divider,
    IconButton,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Tooltip,
    Paper,
    Menu,
    Skeleton,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3Context } from "../../hooks";
import { getAddresses } from "../../constants";
import { messages } from "../../constants/messages";
import { warning } from "../../store/slices/messages-slice";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import ClaimingDialog from "./ClaimDialog";
import formatError from "./ErrorFormat";
import { Popper } from "@material-ui/core";
import ReactPlayer from "react-player";
import { ErrorBoundary } from "react-error-boundary";

interface NftData {
    id: BigNumber;
    image: string;
    name: string;
    fee: BigNumber;
    monthlyCost: BigNumber;
}
interface NFTprops {
    id: BigNumber;
    openModal?: (data: NftData) => void;
    enrolled?: boolean;
    reloadBalance: () => void;
    select: (id: BigNumber) => void;
    unselect: (id: BigNumber) => void;
    selected: Array<BigNumber>;
}

const ipfsFallbackUrl = "https://nfts.stickmansaga.wtf/images/";

const NFT: React.FC<NFTprops> = ({ id, openModal, enrolled, reloadBalance, select, unselect, selected }) => {
    const [data, setData] = useState<any>({ name: "", image: "" });
    const [didLoad, setDidLoad] = useState<boolean>(false);

    useEffect(() => {
        const idStr = id.toString();
        const imgIDStr = "0".repeat(4 - idStr.length > 0 ? 4 - idStr.length : 0) + idStr;
        setData({ name: "unknown", image: ipfsFallbackUrl + imgIDStr + ".jpg" });
    }, [id]);

    const imSelected = selected.includes(id);

    const ImageSkeleton = () => {
        return <Skeleton variant="rectangular" width={240} height={260} key={id.toString() + "skeleton"} style={{ display: didLoad ? "none" : undefined }} />;
    };

    return (
        <>
            <Grid item xs={12} sm={6} md={4} key={id.toString()} sx={{ minWidth: "18rem" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        border: "1px solid #5e5e5e",
                        borderRadius: "10px",
                        padding: "10px",
                        backdropFilter: "blur(20px)",
                    }}
                >
                    <img
                        style={{ display: didLoad ? undefined : "none" }}
                        src={data.image}
                        onError={e => {
                            setDidLoad(false);
                        }}
                        key={id.toString() + "img"}
                        onLoad={() => {
                            setDidLoad(true);
                        }}
                    />

                    <ImageSkeleton></ImageSkeleton>

                    {enrolled ? (
                        <Box
                            p={0}
                            sx={{
                                backgroundColor: "transparent",
                                margin: "0px 1px 0px 1px",
                                borderBottomLeftRadius: "5px",
                                borderBottomRightRadius: "5px",
                                position: "relative",
                            }}
                        >
                            <Box textAlign="center">
                                <Button
                                    variant={imSelected ? "outlined" : undefined}
                                    style={{
                                        height: "20px",
                                        width: "71px",
                                        fontSize: "10px",
                                    }}
                                    sx={{
                                        textDecoration: "underline",
                                        color: "text.secondary",
                                        marginTop: "5px",
                                    }}
                                    onClick={() => {
                                        if (imSelected) {
                                            unselect(id);
                                        } else {
                                            select(id);
                                        }
                                    }}
                                >
                                    {imSelected ? "Selected" : "Unstake"}
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        <Box textAlign="center" sx={{ backgroundColor: "transparent", padding: "10px", paddingTop: "20px", paddingBottom: "0px" }}>
                            <Grid container direction="row" spacing={2} justifyContent="space-around">
                                <Button
                                    variant={imSelected ? "contained" : "outlined"}
                                    onClick={() => {
                                        if (imSelected) {
                                            unselect(id);
                                        } else {
                                            select(id);
                                        }
                                    }}
                                >
                                    {imSelected ? "Selected" : "Select"}
                                </Button>
                            </Grid>
                        </Box>
                    )}
                </Box>
            </Grid>
        </>
    );
};

export default NFT;
