import { ethers } from "ethers";
import { getAddresses } from "../../constants";
import { TimeTokenContract, MemoTokenContract, MimTokenContract, PresaleContract, StakingContract } from "../../abi";
import { setAll } from "../../helpers";

import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { JsonRpcProvider, StaticJsonRpcProvider } from "@ethersproject/providers";
import { Bond } from "../../helpers/bond/bond";
import { Networks } from "../../constants/blockchain";
import React from "react";
import { RootState } from "../store";
import { IToken } from "../../helpers/tokens";

interface IGetBalances {
    address: string;
    networkID: Networks;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
}

interface IAccountBalances {
    balances: {
        wssb: string;
        warmUpssb: string;
        warmupEnd: string;
        ssb: string;
        sb: string;
    };
}

export const getBalances = createAsyncThunk("account/getBalances", async ({ address, networkID, provider }: IGetBalances): Promise<IAccountBalances> => {
    console.log("account/getBalances");
    const addresses = getAddresses(networkID);
    try {
        const ssbContract = new ethers.Contract(addresses.SSB_ADDRESS, MemoTokenContract, provider);
        const wssbContract = new ethers.Contract(addresses.WSSB_ADDRESS, MemoTokenContract, provider);
        const ssbBalance = 0; //await ssbContract.balanceOf(address);
        //const wssbBalance = await wssbContract.balanceOf(address);
        const wssbBalance = 0;
        const sbContract = new ethers.Contract(addresses.SB_ADDRESS, TimeTokenContract, provider);
        const sbBalance = 0; //await sbContract.balanceOf(address);
        const stakingContract = new ethers.Contract(addresses.STAKING_ADDRESS, StakingContract, provider);
        const warmupInfo = 0; //await stakingContract.warmupInfo(address);
        const epoch = 0; //await stakingContract.epoch();
        const warmupEnd = 0; //Number(warmupInfo.expiry) - Number(epoch.number);
        const warmupBalance = 0; //await ssbContract.balanceForGons(warmupInfo.gons);
        return {
            balances: {
                wssb: ethers.utils.formatUnits(wssbBalance, "gwei"),
                warmUpssb: ethers.utils.formatUnits(warmupBalance, "gwei"),
                warmupEnd: String(warmupEnd),
                ssb: ethers.utils.formatUnits(ssbBalance, "gwei"),
                sb: ethers.utils.formatUnits(sbBalance, "gwei"),
            },
        };
    } catch (error) {
        console.log("1");
        console.warn(error);
    }
    return {
        balances: {
            wssb: "err",
            warmUpssb: "err",
            warmupEnd: "err",
            ssb: "err",
            sb: "err",
        },
    };
});

interface ILoadAccountDetails {
    address: string;
    networkID: Networks;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
}

interface IUserAccountDetails {
    balances: {
        sb: string;
        ssb: string;
        wssb: string;
        warmUpssb: string;
        warmupEnd: string;
    };
    redeeming: {
        sb: number;
    };
    staking: {
        sb: number;
        ssb: number;
    };
}

export const loadAccountDetails = createAsyncThunk("account/loadAccountDetails", async ({ networkID, provider, address }: ILoadAccountDetails): Promise<IUserAccountDetails> => {
    console.log("account/loadAccountDetails");

    let sbBalance = 0;
    let ssbBalance = 0;
    let wssbBalance = 0;
    let stakeAllowance = 0;
    let unstakeAllowance = 0;
    let redeemAllowance = 0;
    let warmupBalance = 0;
    let warmupEnd = 0;
    const addresses = getAddresses(networkID);
    try {
        if (addresses.SB_ADDRESS) {
            const sbContract = new ethers.Contract(addresses.SB_ADDRESS, TimeTokenContract, provider);
            sbBalance = 0; //await sbContract.balanceOf(address);
            stakeAllowance = 0; //await sbContract.allowance(address, addresses.STAKING_HELPER_ADDRESS);
            redeemAllowance = 0; //await sbContract.allowance(address, addresses.REDEEM_ADDRESS);
        }

        if (addresses.SSB_ADDRESS) {
            const ssbContract = new ethers.Contract(addresses.SSB_ADDRESS, MemoTokenContract, provider);
            ssbBalance = 0; //await ssbContract.balanceOf(address);
            unstakeAllowance = 0; //await ssbContract.allowance(address, addresses.STAKING_ADDRESS);
        }

        if (addresses.WSSB_ADDRESS) {
            const wssbContract = new ethers.Contract(addresses.WSSB_ADDRESS, MemoTokenContract, provider);
            wssbBalance = 0;
            // wssbBalance = await wssbContract.balanceOf(address);
        }

        if (addresses.SSB_ADDRESS && addresses.STAKING_ADDRESS) {
            const ssbContract = new ethers.Contract(addresses.SSB_ADDRESS, MemoTokenContract, provider);
            const stakingContract = new ethers.Contract(addresses.STAKING_ADDRESS, StakingContract, provider);
            const warmupInfo = 0; //await stakingContract.warmupInfo(address);
            const epoch = 0; // await stakingContract.epoch();
            warmupEnd = 0; // Number(warmupInfo.expiry) - Number(epoch.number);
            warmupBalance = 0; //await ssbContract.balanceForGons(warmupInfo.gons);
        }
    } catch (error) {
        console.log("1");
        console.warn(error);
    }
    return {
        balances: {
            wssb: ethers.utils.formatEther(wssbBalance),
            warmUpssb: ethers.utils.formatUnits(warmupBalance, "gwei"),
            warmupEnd: String(warmupEnd),
            ssb: ethers.utils.formatUnits(ssbBalance, "gwei"),
            sb: ethers.utils.formatUnits(sbBalance, "gwei"),
        },
        redeeming: {
            sb: Number(redeemAllowance),
        },
        staking: {
            sb: Number(stakeAllowance),
            ssb: Number(unstakeAllowance),
        },
    };
});

interface ICalcUserBondDetails {
    address: string;
    bond: Bond;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    networkID: Networks;
}

export interface IUserBondDetails {
    allowance: number;
    balance: number;
    avaxBalance: number;
    interestDue: number;
    bondMaturationBlock: number;
    pendingPayout: number; //Payout formatted in gwei.
}

export const calculateUserBondDetails = createAsyncThunk("account/calculateUserBondDetails", async ({ address, bond, networkID, provider }: ICalcUserBondDetails) => {
    console.log("account/calculateUserBondDetails");
    if (!address) {
        return new Promise<any>(resevle => {
            resevle({
                bond: "",
                displayName: "",
                bondIconSvg: "",
                isLP: false,
                allowance: 0,
                balance: 0,
                interestDue: 0,
                bondMaturationBlock: 0,
                pendingPayout: "",
                avaxBalance: 0,
            });
        });
    }
    try {
        const bondContract = bond.getContractForBond(networkID, provider);
        const reserveContract = bond.getContractForReserve(networkID, provider);

        let interestDue, pendingPayout, bondMaturationBlock;

        // const bondDetails = await bondContract.bondInfo(address);
        // interestDue = bondDetails.payout / Math.pow(10, 9);
        // bondMaturationBlock = Number(bondDetails.vesting) + Number(bondDetails.lastTime);
        pendingPayout = 0; //await bondContract.pendingPayoutFor(address);

        let allowance,
            balance = "0";

        allowance = 0; //await reserveContract.allowance(address, bond.getAddressForBond(networkID));
        balance = "0"; //await reserveContract.balanceOf(address);
        const balanceVal = ethers.utils.formatEther(balance);

        const avaxBalance = 0; //await provider.getSigner().getBalance();
        const avaxVal = ethers.utils.formatEther(avaxBalance);

        const pendingPayoutVal = ethers.utils.formatUnits(pendingPayout, "gwei");

        return {
            bond: bond.name,
            displayName: bond.displayName,
            bondIconSvg: bond.bondIconSvg,
            isLP: bond.isLP,
            allowance: Number(allowance),
            balance: Number(balanceVal),
            avaxBalance: Number(avaxVal),
            interestDue,
            bondMaturationBlock,
            pendingPayout: Number(pendingPayoutVal),
        };
    } catch (error) {
        console.log("3");
        console.warn(error);
    }
    return new Promise<any>(resevle => {
        resevle({
            bond: "",
            displayName: "",
            bondIconSvg: "",
            isLP: false,
            allowance: 0,
            balance: 0,
            interestDue: 0,
            bondMaturationBlock: 0,
            pendingPayout: "",
            avaxBalance: 0,
        });
    });
});

interface ICalcUserTokenDetails {
    address: string;
    token: IToken;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    networkID: Networks;
}

export interface IUserTokenDetails {
    allowance: number;
    balance: number;
    isAvax?: boolean;
}

export const calculateUserTokenDetails = createAsyncThunk("account/calculateUserTokenDetails", async ({ address, token, networkID, provider }: ICalcUserTokenDetails) => {
    console.log("account/calculateUserTokenDetails");
    if (!address) {
        return new Promise<any>(resevle => {
            resevle({
                token: "",
                address: "",
                img: "",
                allowance: 0,
                balance: 0,
            });
        });
    }

    if (token.isAvax) {
        const avaxBalance = 0; //await provider.getSigner().getBalance();
        const avaxVal = 0; //ethers.utils.formatEther(avaxBalance);

        return {
            token: token.name,
            tokenIcon: token.img,
            balance: Number(avaxVal),
            isAvax: true,
        };
    }

    //const addresses = getAddresses(networkID);
    //console.log(token);
    //const tokenContract = new ethers.Contract(token.address, MimTokenContract, provider);

    let allowance,
        balance = "0";
    try {
        allowance = 0; //await tokenContract.allowance(address, addresses.ZAPIN_ADDRESS);
        balance = "0"; //await tokenContract.balanceOf(address);
    } catch (error) {
        console.log("4");
        console.warn(error);
    }
    const balanceVal = Number(balance) / Math.pow(10, token.decimals);

    return {
        token: token.name,
        address: token.address,
        img: token.img,
        allowance: Number(allowance),
        balance: Number(balanceVal),
    };
});

export interface IAccountSlice {
    bonds: { [key: string]: IUserBondDetails };
    balances: {
        wssb: string;
        ssb: string;
        sb: string;
        warmUpssb: string;
        warmupEnd: string;
    };
    loading: boolean;
    redeeming: {
        sb: number;
    };
    staking: {
        sb: number;
        ssb: number;
    };
    presale: {
        allowance: number;
    };
    tokens: { [key: string]: IUserTokenDetails };
}

const initialState: IAccountSlice = {
    loading: true,
    bonds: {},
    balances: { wssb: "", warmUpssb: "", ssb: "", sb: "", warmupEnd: "" },
    staking: { sb: 0, ssb: 0 },
    redeeming: { sb: 0 },
    presale: { allowance: 0 },
    tokens: {},
};

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        fetchAccountSuccess(state, action) {
            setAll(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadAccountDetails.pending, state => {
                state.loading = true;
            })
            .addCase(loadAccountDetails.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadAccountDetails.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
            .addCase(getBalances.pending, state => {
                state.loading = true;
            })
            .addCase(getBalances.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(getBalances.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
            .addCase(calculateUserBondDetails.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(calculateUserBondDetails.fulfilled, (state, action) => {
                if (!action.payload) return;
                const bond = action.payload.bond;
                state.bonds[bond] = action.payload;
                state.loading = false;
            })
            .addCase(calculateUserBondDetails.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
            .addCase(calculateUserTokenDetails.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(calculateUserTokenDetails.fulfilled, (state, action) => {
                if (!action.payload) return;
                const token = action.payload.token;
                state.tokens[token] = action.payload;
                state.loading = false;
            })
            .addCase(calculateUserTokenDetails.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            });
    },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
