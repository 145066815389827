import AvaxIcon from "../assets/tokens/AVAX.svg";
import AaveIcon from "../assets/tokens/AAVE.e.png";
import ApeXIcon from "../assets/tokens/Ape-X.png";
import ApeinIcon from "../assets/tokens/APEIN.png";
import BifiIcon from "../assets/tokens/BIFI.png";
import BlizzIcon from "../assets/tokens/BLIZZ.png";
import BnbIcon from "../assets/tokens/BNB.png";
import BoofiIcon from "../assets/tokens/BOOFI.png";
import ChartIcon from "../assets/tokens/CHART.png";
import DaiEIcon from "../assets/tokens/DAI.e.png";
import DreggIcon from "../assets/tokens/DREGG.png";
import EleIcon from "../assets/tokens/ELE.png";
import ElkIcon from "../assets/tokens/ELK.png";
import FraxIcon from "../assets/tokens/FRAX.png";
import GbIcon from "../assets/tokens/GB.png";
import HatIcon from "../assets/tokens/HAT.png";
import HuskyIcon from "../assets/tokens/HUSKY.png";
import IceIcon from "../assets/tokens/ICE.png";
import JoeIcon from "../assets/tokens/JOE.png";
import KloIcon from "../assets/tokens/KLO.png";
import LinkEIcon from "../assets/tokens/LINK.e.png";
import MainIcon from "../assets/tokens/MAI.png";
import MimIcon from "../assets/tokens/MIM.svg";
import MYakIcon from "../assets/tokens/mYAK.png";
import OliveIcon from "../assets/tokens/OLIVE.png";
import PefiIcon from "../assets/tokens/PEFI.png";
import PngIcon from "../assets/tokens/PNG.png";
import QiIcon from "../assets/tokens/QI.png";
import RelayIcon from "../assets/tokens/RELAY.png";
import SherpaIcon from "../assets/tokens/SHERPA.png";
import ShibxIcon from "../assets/tokens/SHIBX.png";
import SingIcon from "../assets/tokens/SING.png";
import SnobIcon from "../assets/tokens/SNOB.png";
import SpellIcon from "../assets/tokens/SPELL.png";
import SushiEIcon from "../assets/tokens/SUSHI.e.png";
import SynIcon from "../assets/tokens/SYN.png";
import TeddyIcon from "../assets/tokens/TEDDY.png";
import SbIcon from "../assets/tokens/SB.svg";
import TsdIcon from "../assets/tokens/TSD.png";
import UsdcEIcon from "../assets/tokens/USDC.e.png";
import UsdtEIcon from "../assets/tokens/USDT.e.png";
import VsoIcon from "../assets/tokens/VSO.png";
import WavaxIcon from "../assets/tokens/WAVAX.png";
import WBtcIcon from "../assets/tokens/WBTC.e.png";
import WetIcon from "../assets/tokens/WET.png";
import WethEIcon from "../assets/tokens/WETH.e.png";
import XavaIcon from "../assets/tokens/XAVA.png";
import YakIcon from "../assets/tokens/YAK.png";

export interface IToken {
    name: string;
    address: string;
    img: string;
    isAvax?: boolean;
    decimals: number;
}

export const avax: IToken = {
    name: "MATIC",
    isAvax: true,
    img: AvaxIcon,
    address: "",
    decimals: 18,
};

export const mim: IToken = {
    name: "USDC",
    address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    img: UsdcEIcon,
    decimals: 6,
};

export const wavax: IToken = {
    name: "wMatic",
    address: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    img: UsdcEIcon,
    decimals: 6,
};

const sb: IToken = {
    name: "DOM",
    address: "0x0e2c818fea38e7df50410f772b7d59af20589a62",
    img: UsdcEIcon,
    decimals: 6,
};

const sdom: IToken = {
    name: "sDOM",
    address: "0x2641D46f639C51E365B03439696228A5E3601f0f",
    img: UsdcEIcon,
    decimals: 6,
};

export default [mim, sb, wavax];
