import { Networks } from "../../constants/blockchain";
import { LPBond, CustomLPBond } from "./lp-bond";
import { StableBond, CustomBond } from "./stable-bond";

import MimIcon from "../../assets/tokens/MIM.svg";
import AvaxIcon from "../../assets/tokens/AVAX.svg";
import mimSbIcon from "../../assets/tokens/SB-MIM.png";

import AvaxSbIcon from "../../assets/tokens/SB-AVAX.png";
import MimSdogIcon from "../../assets/tokens/SDOG-MIM.svg";
import { StableBondContract, LpBondContract, WavaxBondContract, StableReserveContract, LpReserveContract } from "../../abi";

export const mim = new StableBond({
    // add USDC BOND details here instead of MIM
    name: "USDC",
    displayName: "USDC",
    bondToken: "MIM",
    bondIconSvg: MimIcon,
    bondContractABI: StableBondContract,
    reserveContractAbi: StableReserveContract,
    networkAddrs: {
        [Networks.AVAX]: {
            bondAddress: "0x8A3ADa12E44f40DEB19dF2db2e11935289Ccb116",
            reserveAddress: "0x130966628846BFd36ff31a822705796e8cb8C18D",
        },
        [Networks.POLYGON]: {
            // switch address for polygon
            bondAddress: "0x8A3ADa12E44f40DEB19dF2db2e11935289Ccb116",
            reserveAddress: "0x130966628846BFd36ff31a822705796e8cb8C18D",
        },
        [Networks.TEST]: {
            // switch address for polygon
            bondAddress: "0x8A3ADa12E44f40DEB19dF2db2e11935289Ccb116",
            reserveAddress: "0x130966628846BFd36ff31a822705796e8cb8C18D",
        },
    },
});

export const wavax = new CustomBond({
    name: "wavax",
    displayName: "wAVAX",
    bondToken: "AVAX",
    bondIconSvg: AvaxIcon,
    bondContractABI: WavaxBondContract,
    reserveContractAbi: StableReserveContract,
    networkAddrs: {
        [Networks.AVAX]: {
            bondAddress: "0x0000000000000000000000000000000000000000",
            reserveAddress: "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
        },
        [Networks.POLYGON]: {
            bondAddress: "0x0000000000000000000000000000000000000000",
            reserveAddress: "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
        },
        [Networks.TEST]: {
            bondAddress: "0x0000000000000000000000000000000000000000",
            reserveAddress: "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
        },
    },
});

export const mimSb = new LPBond({
    name: "mim_dom_lp",
    displayName: "DOM-USDC LP",
    bondToken: "MIM",
    bondIconSvg: mimSbIcon,
    bondContractABI: LpBondContract,
    reserveContractAbi: LpReserveContract,
    networkAddrs: {
        [Networks.AVAX]: {
            bondAddress: "0x24De583bC83318f3586628225a87BB613e4c3284",
            reserveAddress: "0xcac0101426fdbfcfe7baff17ddc6652d92540480",
        },
        [Networks.POLYGON]: {
            bondAddress: "0x24De583bC83318f3586628225a87BB613e4c3284",
            reserveAddress: "0xcac0101426fdbfcfe7baff17ddc6652d92540480",
        },
        [Networks.TEST]: {
            bondAddress: "0x24De583bC83318f3586628225a87BB613e4c3284",
            reserveAddress: "0xcac0101426fdbfcfe7baff17ddc6652d92540480",
        },
    },
    lpUrl: "https://traderjoexyz.com/pool/AVAX/0x0000000000000000000000000000000000000000",
});

export const avaxSb = new CustomLPBond({
    name: "avax_dom_lp",
    displayName: "DOM-AVAX LP",
    bondToken: "AVAX",
    bondIconSvg: AvaxSbIcon,
    bondContractABI: LpBondContract,
    reserveContractAbi: LpReserveContract,
    networkAddrs: {
        [Networks.AVAX]: {
            bondAddress: "0x0000000000000000000000000000000000000000",
            reserveAddress: "0x0000000000000000000000000000000000000000",
        },
        [Networks.POLYGON]: {
            bondAddress: "0x0000000000000000000000000000000000000000",
            reserveAddress: "0x0000000000000000000000000000000000000000",
        },
        [Networks.TEST]: {
            bondAddress: "0x0000000000000000000000000000000000000000",
            reserveAddress: "0x0000000000000000000000000000000000000000",
        },
    },
    lpUrl: "https://traderjoexyz.com/pool/AVAX/0x0000000000000000000000000000000000000000",
});

export default [mim];
