import SbImg from "../assets/tokens/SB.png";
import SsbImg from "../assets/tokens/SSB.png";
import Stix from "../assets/tokens/STIX_logo.png";
function toUrl(tokenPath: string): string {
    const host = window.location.origin;
    return `${host}/${tokenPath}`;
}

export function getTokenUrl(name: string) {
    if (name === "dom") {
        return toUrl(SbImg);
    }
    if (name === "stix") {
        return toUrl(Stix);
    }

    if (name === "sdom") {
        return toUrl(SsbImg);
    }

    throw Error(`Token url doesn't support: ${name}`);
}
