import { useState } from "react";
import { getAddresses, DEFAULD_NETWORK } from "../../../constants";
import { useSelector } from "react-redux";
import { Link, Fade, Popper } from "@material-ui/core";
import "./snowbank-menu.scss";
import { IReduxState } from "../../../store/slices/state.interface";

import { useTranslation } from "react-i18next";
import { addTokenToWallet } from "./addTokenToWallet";

function SnowbankMenu() {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const isEthereumAPIAvailable = window.ethereum;

    const networkID = useSelector<IReduxState, number>(state => {
        return (state.app && state.app.networkID) || DEFAULD_NETWORK;
    });

    const addresses = getAddresses(networkID);

    const SSB_ADDRESS = addresses.SSB_ADDRESS;
    const SB_ADDRESS = addresses.SB_ADDRESS;

    const handleClick = (event: any) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);

    return (
        <div className="sb-menu-root" onMouseEnter={e => handleClick(e)} onMouseLeave={e => handleClick(e)}>
            <div className="sb-menu-btn">
                <p>{t("BuySB")}</p>
            </div>
            <Popper className="sb-menu-popper" open={open} anchorEl={anchorEl} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={200}>
                        <div className="tooltip">
                            <Link className="tooltip-item" href={`https://app.uniswap.org/#/swap?outputCurrency=${SB_ADDRESS}`} target="_blank">
                                <p>{t("BuyOnUniswap")}</p>
                            </Link>

                            {isEthereumAPIAvailable && (
                                <div className="add-tokens">
                                    <div className="divider" />
                                    <p className="add-tokens-title">{t("AddTokenToWallet")}</p>
                                    <div className="divider" />
                                    <div id="add_token_to_wallet" className="tooltip-item" onClick={addTokenToWallet("STIX", SB_ADDRESS)}>
                                        <p>↑ STIX</p>
                                    </div>
                                    {/* <div className="tooltip-item" onClick={addTokenToWallet("sDOM", SSB_ADDRESS)}>
                                        <p>↑ sDOM</p>
                                    </div> */}
                                </div>
                            )}
                        </div>
                    </Fade>
                )}
            </Popper>
        </div>
    );
}

export default SnowbankMenu;
