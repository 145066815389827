import React, { FC, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { Box, Button, Chip, Typography, Grid, Divider } from "@mui/material";
import { MimTokenContract, Token, StakingContract, MemoTokenContract, TimeTokenContract, RedeemContract, NftStaking, NftERC721 } from "../../abi";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ethers, Signer, BigNumber } from "ethers";

import NFT from "./NFT";
interface Props {
    enrolled?: boolean;
    openModal?: (id: NftData) => void;
    owned: Array<BigNumber>;
    reloadBalance: () => void;
    select: (id: BigNumber) => void;
    unselect: (id: BigNumber) => void;
    selected: Array<BigNumber>;
}

interface NFTItem {
    tokenId: string;
    node: string; // name of node uri of the asset's preview
    imageURI: string; // uri of the original asset
    reward: string; // reward token
}

interface NftData {
    id: BigNumber;
    image: string;
    name: string;
    fee: BigNumber;
    monthlyCost: BigNumber;
}

const ITEM_HEIGHT = 48;

const NFTTable: FC<Props> = ({ enrolled, openModal, owned, reloadBalance, select, unselect, selected }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    return (
        <div>
            <Grid container spacing={3} mt={2} justifyContent="center" style={{ marginTop: "0px" }}>
                {owned.map(nftId => {
                    return (
                        <NFT
                            id={nftId}
                            openModal={openModal}
                            enrolled={enrolled}
                            key={"nftkey_" + nftId.toString()}
                            reloadBalance={reloadBalance}
                            selected={selected}
                            unselect={unselect}
                            select={select}
                        ></NFT>
                    );
                })}
            </Grid>
        </div>
    );
};

export default NFTTable;
