import { Box, Button, Chip, Typography, Grid, Divider, IconButton, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ethers, BigNumber } from "ethers";
import { addTokenToWallet } from "src/components/Header/snowbank-menu/addTokenToWallet";

export default function ClaimingDialog(props: any) {
    const [claimDialogCheckbox, setClaimDialogCheckbox] = useState<boolean>(false);

    return (
        <Dialog
            open={props.claimDialog}
            onClose={() => {
                props.setClaimDialog(false);
            }}
        >
            <Box
                sx={{
                    backgroundColor: "#000",
                    border: "2px solid rgba(255,255,255,0.3)",
                    backdropFilter: "blur(30px)",
                }}
            >
                <DialogTitle>
                    <Typography textAlign="center">Claim STIX Tokens</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => props.setClaimDialog(false)}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ color: "#bebebe" }}>
                    <Typography>You will receive {Number(ethers.utils.formatEther(props.claimable)).toFixed(2)} STIX</Typography>
                    <Divider sx={{ margin: "20px 0", borderColor: "#bebebe" }} />

                    <Button
                        fullWidth
                        disabled={props.claiming}
                        onClick={props.onClaim}
                        sx={{
                            background: "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(36, 36, 36, 0.355) 100%)",
                            borderRadius: "17px",
                            padding: "5px 15px",
                            border: "0.994648px solid rgba(255, 255, 255, 0.3)",
                            margin: "10px 10px 10px 0px",
                        }}
                    >
                        {props.claiming ? "Processing transaction" : "Claim"}
                    </Button>
                </DialogContent>
            </Box>
        </Dialog>
    );
}
