import { AppBar, Toolbar, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuIcon from "../../assets/icons/hamburger.svg";
import SnowbankMenu from "./snowbank-menu";
import ConnectButton from "./connect-button";
import "./header.scss";
import { DRAWER_WIDTH, TRANSITION_DURATION } from "../../constants/style";
import { Link } from "react-router-dom";

interface IHeader {
    handleDrawerToggle: () => void;
    drawe: boolean;
}

const useStyles = makeStyles(theme => ({
    appBar: {
        [theme.breakpoints.up("sm")]: {
            width: "100%",
            padding: "20px 0 0 0",
        },
        justifyContent: "flex-end",
        alignItems: "flex-end",
        background: "transparent",
        backdropFilter: "none",
        zIndex: 10,
    },
    topBar: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: TRANSITION_DURATION,
        }),
        marginLeft: DRAWER_WIDTH,
    },
    topBarShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: TRANSITION_DURATION,
        }),
        marginLeft: 0,
    },
}));

function Header({ handleDrawerToggle, drawe }: IHeader) {
    const classes = useStyles();
    const isVerySmallScreen = useMediaQuery("(max-width: 400px)");

    return (
        <div className={`${classes.topBar} ${!drawe && classes.topBarShift}`}>
            <AppBar position="sticky" className={classes.appBar} elevation={0}>
                <Box className="dapp-information-box">
                    <p className="dapp-information-text">
                        Always make sure the URL is{" "}
                        <a href="https://stickmansaga.wtf/" target="_blank">
                            stickmansaga.wtf
                        </a>
                        . Bookmark it to be safe.
                    </p>
                </Box>
                <Toolbar disableGutters className="dapp-topbar">
                    {!isVerySmallScreen ? (
                        <div
                            style={{
                                height: "100px",
                                overflow: "hidden",
                            }}
                        >
                            <a href={`https://www.stickmansaga.com/`} target="_blank" rel="noreferrer">
                                <img src="./Untitled_Artwork-2.png" height={200} />
                            </a>
                        </div>
                    ) : (
                        <div
                            style={{
                                height: "50px",
                                overflow: "hidden",
                            }}
                        >
                            <a href={`https://www.stickmansaga.com/`} target="_blank" rel="noreferrer">
                                <img src="./Untitled_Artwork-2.png" height={90} />
                            </a>
                        </div>
                    )}
                    {/* <div onClick={handleDrawerToggle} className="dapp-topbar-slider-btn">
                        <img src={MenuIcon} alt="" />
                    </div> */}
                    <div className="dapp-topbar-btns-wrap">
                        {!isVerySmallScreen && <SnowbankMenu />}
                        <ConnectButton />
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default Header;
