import React from "react";
import MuiTypography, { TypographyProps as MuiTypographyProps } from "@mui/material/Typography";

interface TypographyProps extends MuiTypographyProps {}

const Typography: React.FC<TypographyProps> = ({ children, ...otherProps }) => {
    return <MuiTypography {...otherProps}>{children}</MuiTypography>;
};

export default Typography;
